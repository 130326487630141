<template >
  <v-chip
    v-on="data.on"
    :color="close ? '#484848' : '#696969'"
    @click:close="$emit('close')"
    @click="$emit('add', data.item.element)"
    :close="close"
    class="ma-0 mr-2"
    outlined
  >
    <v-avatar outlined>
      <v-img :src="img(data.item)" />
    </v-avatar>
    <div class="ml-2">
      <span class="item">{{ data.item.name }}</span>
    </div>
  </v-chip>
</template>

<script>
import icons, { getIcon } from "@/icons.js";

export default {
  props: {
    data: {},
    close: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return { icons: icons };
  },
  methods: {
    img(item) {
      return getIcon(item.element ? item.element.classes : []);
    },
  },
};
</script>

<style>
.item {
  font-size: 10px;
  max-width: 150px;
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>