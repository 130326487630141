var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.properties)?_c('div',{staticClass:"properties"},[[_c('v-tabs',{staticClass:"elevation-24",attrs:{"color":"black","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabs),function(tab,i){return _c('v-tab',{key:'title-' + i,staticClass:"text-none",staticStyle:{"font-size":"12px !important"},attrs:{"disabled":_vm.tabs[i].content.length == 0}},[_vm._v(_vm._s(_vm.tabs[i].title))])}),(_vm.notes.enabled)?_c('v-tab',{staticClass:"text-none",staticStyle:{"font-size":"12px !important"}},[_vm._v("Notes")]):_vm._e()],2),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabs),function(tab,i){return _c('v-tab-item',{key:'content' + i},[_c('v-data-iterator',{attrs:{"items":_vm.tabs[i].content,"items-per-page":10,"hide-default-footer":_vm.tabs[i].content.length <= 10},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-card',{staticClass:"py-2",attrs:{"raised":true,"outlined":true}},_vm._l((props.items),function(item,i){return _c('v-card',{key:'item-' + i,attrs:{"flat":"","tile":""}},[_c('v-row',{staticClass:"mx-0",attrs:{"no-gutters":""}},[_c('v-col',[(
                        tab.style === 'action' && item.key !== 'Condition'
                      )?_c('v-row',{staticClass:"mx-2 my-0"},[_c('v-col',{attrs:{"cols":"4"}},[('href' in item)?_c('a',{attrs:{"href":item.href,"target":"_blank"}},[_vm._v(_vm._s(item.key))]):_c('div',[_vm._v(_vm._s(item.key))])]),_c('v-col',{staticClass:"grey--text",attrs:{"cols":"8"}},[(Array.isArray(item.value))?_c('div',[(item.value.length > 0)?_c('div',[_vm._l((item.value),function(k){return _c('li',{key:'li-' + k},[_vm._v(" "+_vm._s(k)+" ")])}),(item.key === 'Condition Keys')?_c('li',[_c('a',{attrs:{"href":"https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_condition-keys.html","target":"_blank"}},[_c('i',[_vm._v("Global Conditions")])])]):_vm._e()],2):_c('div',[_vm._v("-")])]):_c('div',[_vm._v(_vm._s(item.value))])])],1):(
                        tab.style === 'codeblock' || tab.style == 'action'
                      )?_c('v-card',{staticClass:"ma-1",attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-5 codeblock",domProps:{"innerHTML":_vm._s(item.value)}}),_c('v-row',[_c('v-col',{staticClass:"mx-5 mt-n3 overline",staticStyle:{"font-size":"9px !important"},attrs:{"align":"right"}},[_vm._v(_vm._s(item.key))])],1)],1):(tab.style === 'actions')?_c('v-expansion-panels',{attrs:{"accordian":true}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-size":"12px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({class:item.effect.includes('Conditional')
                                      ? 'conditional-action'
                                      : '',attrs:{"color":item.effect.includes('Allow')
                                      ? 'green'
                                      : 'red'}},on),[_vm._v("mdi-chevron-down")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.effect))])])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-expansion-panel-content',{staticClass:"font-weight-thin ma-5px",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(item.description))])],1)],1):(tab.style === 'attack')?_c('v-card',{staticClass:"pt-5 ma-2",attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pb-2",staticStyle:{"width":"60px","float":"left"}},[_vm._v(" Step "+_vm._s(i + 1)+": ")]),_c('div',{staticClass:"grey--text",staticStyle:{"width":"calc(100% - 70px)","float":"left"}},[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-card',[_c('div',{staticClass:"codeblock",domProps:{"innerHTML":_vm._s(item.command)}})])],1)],1)],1)],1):_c('v-row',{staticClass:"mx-2 pa-1"},[_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(item.key))]),_c('v-col',{staticClass:"grey--text",attrs:{"cols":"7"}},[_vm._v(_vm._s(item.value))])],1)],1)],1)],1)}),1)]}}],null,true)})],1)}),(_vm.notes.enabled)?_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-card',{attrs:{"raised":true,"outlined":true}},[_c('v-textarea',{staticClass:"mx-8 mt-8",attrs:{"outlined":"","auto-grow":"","full-width":"","clearable":"","value":_vm.notes.value,"rules":[
                  _vm.notes.connected ||
                    'Database disconnected, changes will not be saved',
                ]},on:{"input":_vm.notes_save}})],1)],1)],1)],1):_vm._e()],2)]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }